import http from "../../utils/http"

/**
 * รับข้อมูลการเริ่มต้นคําสั่งซื้อ
 * @param {object} params
 */
export function payment(params) {
    return http({
        url: "/api/ordercreate/payment",
        data: params,
        forceLogin: true
    })
}

/**
 * รับข้อมูลการเริ่มต้นคําสั่งซื้อ
 * @param {object} params
 */
export function calculate(params) {
    return http({
        url: "/api/ordercreate/calculate",
        data: params,
        forceLogin: true
    })
}

/**
 * การสร้างคําสั่งซื้อ
 * @param {object} params
 */
export function orderCreate(params) {
    return http({
        url: "/api/ordercreate/create",
        data: params,
        forceLogin: true
    })
}

/**
 * ยืนยันรหัสผ่านการชําระเงิน
 * @param {object} params
 */
export function checkpaypassword(params) {
    return http({
        url: "/api/member/checkpaypassword",
        data: params,
        forceLogin: true
    })
}

/**
 * รับการกําหนดค่าการชําระเงินยอดคงเหลือ
 * @param {object} params
 */
export function balanceConfig() {
    return http({
        url: "/api/pay/getBalanceConfig",
        data: "",
        forceLogin: true
    })
}
